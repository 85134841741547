<template>
  <div>
    <el-table
        style="width: 99%"
        :data="defaultPermissionArray"
        v-loading="loading"
        element-loading-text="拼命加载中"
        max-height="650"
        border>
      <el-table-column prop="roleName" label="职位" align="center"></el-table-column>
      <el-table-column label="操作" align="center" v-slot="scope">
        <el-button-group>
          <el-button size="mini" type="warning" @click="pcPermissionOpen(scope.row)">分配电脑端权限</el-button>
          <el-button size="mini" type="danger" @click="mobilePermissionOpen(scope.row)">分配移动端权限</el-button>
        </el-button-group>
      </el-table-column>
    </el-table>
    <pc-permission v-if="pcState" :state="pcState"  :defaultPermission="defaultPermission" @close="pcPermissionClose"></pc-permission>
    <mobile-permission v-if="mobileState" :state="mobileState" :defaultPermission="defaultPermission" @close="mobilePermissionClose"></mobile-permission>
  </div>
</template>

<script>
import pcPermission from "@/pages/view/permission/pc-permission"
import mobilePermission from "@/pages/view/permission/mobile-permission"

export default {
  name: "default-permission",
  components: {
    pcPermission,
    mobilePermission
  },
  created() {
    this.queryDefaultPermission()
  },
  data() {
    return {
      defaultPermissionArray: [],
      loading: false,
      pcState: false,
      mobileState: false,
      defaultPermission: {},
    }
  },
  methods: {
    queryDefaultPermission() {
      this.$axios({
        method: "GET",
        url: "/defaultPermission/defaultPermissionList",
      }).then(response => {
        this.defaultPermissionArray = response.data.data
      })
    },
    pcPermissionOpen(val) {
      this.defaultPermission = val
      this.pcState = true
    },
    pcPermissionClose() {
      this.pcState = false
    },
    mobilePermissionOpen(val) {
      this.defaultPermission = val
      this.mobileState = true
    },
    mobilePermissionClose() {
      this.mobileState = false
    }
  }
}
</script>

<style scoped>

</style>