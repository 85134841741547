<template>
  <el-dialog
    :visible.sync="dialogState"
    :modal-append-to-body="false"
    width="40%"
    :title="'移动端权限('+defaultPermission.roleName+')'"
    center
    @close="close">
    <el-row>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="页面权限" name="page">
          <div style="max-height:40vh;overflow: auto;min-height: 15vh">
            <el-tree
                :data="moduleTree"
                show-checkbox
                node-key="id"
                ref="moduleTree"
                :check-strictly="false"
                :default-checked-keys="processModule"
                :props="moduleProps">
            </el-tree>
          </div>
        </el-tab-pane>
        <el-tab-pane label="按钮权限" name="button">
          <div style="max-height:40vh;overflow: auto;min-height: 15vh">
            <el-tree
                :data="buttonTree"
                show-checkbox
                node-key="id"
                ref="buttonTree"
                :check-strictly="false"
                :default-checked-keys="processButton"
                :props="buttonProps">
            </el-tree>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "mobile-permission",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true
    },
    defaultPermission: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogState: this.state,
      activeName: "page",
      moduleTree: [],
      processModule: [],
      moduleProps: {
        children: 'child',
        label: 'viewName',
      },
      buttonTree: [],
      processButton: [],
      buttonProps: {
        label: "buttonName"
      },
    }
  },
  created() {
    this.queryMobileView()
    this.queryAllButtonTree()
  },
  methods: {
    close() {
      this.$emit("close")
    },
    submit() {
      this.$confirm("是否修改"+ this.defaultPermission.roleName+ "的默认系统权限吗？",
          "修改默认权限",{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
        this.savaDefaultPermission()
      }).catch(() => {})
    },
    queryMobileView() {
      this.$axios({
        method: "GET",
        url: "/mobileView/queryMobileViewTreeByViewFilter",
      }).then(response => {
        this.moduleTree = response.data.data
        this.queryDefaultModule(2)
      })
    },
    queryDefaultModule(val) {
      this.$axios({
        method: "GET",
        url: "/defaultPermission/queryDefaultPermission",
        params: {
          roleName: this.defaultPermission.roleName,
          type: val
        }
      }).then(response=>{
        if (val === 2) {
          this.processModule = response.data.data
        } else {
          this.processButton = response.data.data
        }
      })
    },
    queryAllButtonTree() {
      this.$axios({
        method: "GET",
        url: "/button/queryAllButtonTree",
        params: {
          type: 2,
        }
      }).then(response=>{
        this.buttonTree = response.data.data
        this.queryDefaultModule(4)
      })
    },
    savaDefaultPermission() {
      //获取全部树中的虚拟节点
      let fakeModuleIds=this.moduleTree.map(k=>k.id)
      //选中的数组
      let chooseModuleArr=this.$refs.moduleTree.getCheckedKeys();
      //通过循环删除假节点id
      let finalModuleIds = chooseModuleArr.filter(function(value) {
        return fakeModuleIds.indexOf(value) === -1;
      });

      //获取全部树中的虚拟节点
      let fakeButtonIds=this.buttonTree.map(k=>k.id)
      //选中的数组
      let chooseButtonArr=this.$refs.buttonTree.getCheckedKeys();
      //通过循环删除假节点id
      let finalButtonIds = chooseButtonArr.filter(function(value) {
        return fakeButtonIds.indexOf(value) === -1;
      });

      this.$axios({
        method: "POST",
        url: "/defaultPermission/savaDefaultPermission",
        data: {
          roleName: this.defaultPermission.roleName,
          moduleIds: finalModuleIds,
          buttonIds: finalButtonIds,
          type: 2,
        }
      }).then(response=>{
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '分配成功' : response.data.msg,
          type: flag ? 'success' : 'error',
          duration: 1000,
        })
        if (flag) {
          setTimeout(this.close, 2000)
        }
      })
    }
  },
}
</script>

<style scoped>

</style>